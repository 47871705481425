<template>
  <div class="p-4">
    <a-button
      class="float-right mb-3 d-flex align-items-center"
      type="primary"
      @click="onCreateBarcode()"
    >
      <template #icon><i class="bi bi-plus mr-1 mb-1"></i></template>
      Add Barcode
    </a-button>

    <main-barcode></main-barcode>
  </div>
</template>
<script>
import MainBarcode from './MainBarcode.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    MainBarcode,
  },

  computed: {
    ...mapGetters(['allBarcode']),
  },
  methods: {
    ...mapActions(['createBarcode']),

    onCreateBarcode() {
      this.createBarcode();
    },
  },
};
</script>
