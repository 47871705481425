<template>
  <a-table
    :columns="columns"
    :data-source="barcodes"
    bordered
    :scroll="{ y: '55vh', x: '900px' }"
    v-if="barcodes.length > 0"
  >
    <template #title>
      <a-typography-title :level="5">
        Barcodes: &nbsp;
        <a-tag color="blue">{{ barcodes.length }}</a-tag>
      </a-typography-title>
    </template>
    <template #action="{record}">
      <a-popconfirm
        title="Are you sure you want to delete?"
        @confirm="onDeleteBarcode(record.id)"
      >
        <a-button type="danger">Delete</a-button>
      </a-popconfirm>
    </template>
  </a-table>

  <div v-else>
    <p>No barcode found</p>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: ['barcodes', 'tableHeader'],
  methods: {
    ...mapActions(['deleteBarcode']),
    onDeleteBarcode(barcodeId) {
      this.deleteBarcode(barcodeId);
    },
  },
  data() {
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          width: '15%',
          align: 'left',
        },
        {
          title: 'Barcode',
          dataIndex: 'barcode_value',
          width: '70%',
          align: 'left',
        },
        {
          title: 'Actions',
          key: 'action',
          align: 'left',
          slots: { customRender: 'action' },
        },
      ],
    };
  },
};
</script>

<style scoped></style>
